import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At Dashbot, we’re excited to announce a number of new product launches to enable customers to better understand their users and increase user acquisition, engagement, and retention.`}</p>
    <h2>{`New Dashboard`}</h2>
    <p>{`We updated our Dashboard to bubble up key metrics to the front in one view.`}</p>
    <p>{`You can quickly see top messages, intents, and exit messages on the dashboard and dive directly into the funnels to better understand user behavior.`}</p>
    <p>{`Retention, users, and messages are also readily available to quickly see how well your chatbot or voice skill is performing.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/08205923/dashboard2.png",
        "alt": null
      }}></img></p>
    <h2>{`Audience Builder`}</h2>
    <p>{`With our Audience Builder, you can segment your audience based on the data and behaviors to get better insights into who your key users really are. The results can be used to build look-a-like audiences for targeted user acquisition ads.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/08205921/audience-builder-1024x664.png",
        "alt": null
      }}></img></p>
    <h2>{`Retention Comparison Metrics`}</h2>
    <p>{`Want to know how your chatbot or voice skill compares to others based on retention?`}</p>
    <p>{`We updated our Market Metrics reports to include retention. In addition to comparing based on users and interactions, now you can compare your chatbot or voice skill on daily, weekly, and monthly retention.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/08205920/retention-1024x637.png",
        "alt": null
      }}></img></p>
    <h2>{`Audio Session Length Tracking in Voice Skills`}</h2>
    <p>{`Does your voice skill make use of custom audio files?`}</p>
    <p>{`Dashbot can help you track session lengths including the audio length.`}</p>
    <h2>{`Additional Features Coming Soon`}</h2>
    <p>{`Having processed 30 billion messages, we continue to apply data science techniques for deeper, and actionable insights. Stay tuned for additional features around Natural Language Processing (NLP) and proactive suggestions and recommendations.`}</p>
    <h2>{`Product Announcements at SuperBot 2018`}</h2>
    <p>{`Dennis Yang, our co-founder and CPO, discusses the differences between traditional and conversational analytics as well as our new product announcements at Super Bot 2018.`}</p>
    <h3>{`Traditional vs Conversational Analytics`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Hb3LdJzXPmc?feature=oembed" width="1200"></iframe>
    <h3>{`New Product Launches`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/fRuPPX_ti24?feature=oembed" width="1200"></iframe>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot and voice specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      